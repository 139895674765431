import React, { useEffect, useState } from 'react';
import axios from 'axios';

const App = () => {
  const [data, setData] = useState(null);
  const [newRow, setNewRow] = useState({ interactionId: '', date: '', clientName: '', phoneNumber: '' });
  const [prompt, setPrompt] = useState('');
  const [gptResponse, setGptResponse] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Fetching data from backend...');
        const response = await axios.get('/api/data');
        if (typeof response.data === 'object') {
          console.log('Data fetched successfully:', response.data);
          setData(response.data.values);
        } else {
          console.error('Unexpected response format:', response.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewRow((prevRow) => ({ ...prevRow, [name]: value }));
  };

  const handleSubmitRow = async (e) => {
    e.preventDefault();
    const values = [newRow.interactionId, newRow.date, newRow.clientName, newRow.phoneNumber];
    try {
      console.log('Adding new row to Google Sheets...');
      await axios.post('/api/addRow', { values });
      console.log('Row added successfully');
      setNewRow({ interactionId: '', date: '', clientName: '', phoneNumber: '' });
      const response = await axios.get('/api/data');
      if (typeof response.data === 'object') {
        setData(response.data.values);
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('Error adding row:', error);
    }
  };

  const handlePromptChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleSubmitPrompt = async (e) => {
    e.preventDefault();
    try {
      console.log('Submitting prompt to GPT...');
      const response = await axios.post('/api/gpt', { prompt });
      console.log('GPT response received:', response.data.choices[0].text);
      setGptResponse(response.data.choices[0].text);
    } catch (error) {
      console.error('Error interacting with GPT:', error);
    }
  };

  return (
    <div>
      <h1>Google Sheets Data</h1>
      {data ? (
        <table border="1">
          <thead>
            <tr>
              {data[0].map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.slice(1).map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>Loading...</p>
      )}

      <h2>Add a New Row</h2>
      <form onSubmit={handleSubmitRow}>
        <input
          type="text"
          name="interactionId"
          placeholder="Interaction ID"
          value={newRow.interactionId}
          onChange={handleChange}
        />
        <input
          type="text"
          name="date"
          placeholder="Date"
          value={newRow.date}
          onChange={handleChange}
        />
        <input
          type="text"
          name="clientName"
          placeholder="Client Name"
          value={newRow.clientName}
          onChange={handleChange}
        />
        <input
          type="text"
          name="phoneNumber"
          placeholder="Phone Number"
          value={newRow.phoneNumber}
          onChange={handleChange}
        />
        <button type="submit">Add Row</button>
      </form>

      <h2>Ask GPT-3</h2>
      <form onSubmit={handleSubmitPrompt}>
        <textarea
          value={prompt}
          onChange={handlePromptChange}
          placeholder="Ask something..."
        />
        <button type="submit">Submit</button>
      </form>
      <p>Response: {gptResponse}</p>
    </div>
  );
};

export default App;
